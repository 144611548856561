.image_block {

	.image {
		margin: auto;

		img {
			object-fit: cover;
			max-height: 40vw;
			border-radius: 20px;
			@include media-breakpoint-down(xs) {
				border-radius: 0;
				max-height: unset;
			}
		}
		@include media-breakpoint-down(xs) {
			padding: unset;
		}
	}
}