.three_icon_block-container {
	.repeater_content {
		width: 100%;
		margin-bottom: 20px;

		.repeater_heading {
			margin-bottom: 50px;

			h2 {
				margin: auto;
				text-align: center;

				@include media-breakpoint-down(sm) {
					text-align: center;
				}
			}
		}

		.subject_content {
			width: 100%;
			display: flex;
			flex-flow: row;
			flex-wrap: wrap;
			justify-content: space-around;

			.subject_box {
				padding-top: 30px;
				flex-flow: column;
				text-align: center;	
				.repeater_button {
					.button_container {
						padding-top: 40px;
						padding-bottom: 40px;
						@include media-breakpoint-down(xs) {
							padding-top: 20px;
							padding-bottom: 20px;
						}
					}
				}

				img {
					width: 50%;
					margin-left: auto;
					margin-right: auto;
					padding-bottom: 25px;

					@include media-breakpoint-down(sm) {
						padding-bottom: 12.5px;
					}
				}

				h3 {
					padding-bottom: 10px;

					@include media-breakpoint-down(sm) {
						padding-bottom: 5px;
					}
				}
				p {
					font-size: $font-size-400;
				}
				.subject_image {
					display: inline-block;

				}
			}
		}
	}

	.button-three_icon_block {
		margin: auto;
	}
}

.padding-three_icon {
	padding-top: 120px;
	padding-bottom: 120px;

	@include media-breakpoint-down(sm) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}