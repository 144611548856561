.repeater_meilenstein-container {
	padding-bottom: 120px;
	padding-top: 120px;
	@include media-breakpoint-down(sm) {
		padding-bottom: 60px;
		padding-top: 60px;
	}

	.repeater_content {
		.repeater_heading {
			margin: auto;

			h2 {
				text-align: center;
			}

			.slider_navigation {
				text-align: center;
				margin-bottom: 20px;

				a {
					padding: 5px;
					font-size: $font-size-600;
					color: theme-color('secondary') !important;
					-webkit-transition: all 200ms ease-in-out;
					-moz-transition: all 200ms ease-in-out;
					-o-transition: all 200ms ease-in-out;
					transition: all 200ms ease-in-out;

					&,
					&:visited {
						text-decoration: none;
						position: relative;
						color: theme-color('accent-color');
					}

					&:hover:after,
					&:visited:hover:after {
						width: 100%;
					}

					&:after,
					&:visited:after {
						content: '';
						height: 3px;
						position: absolute;
						bottom: -5px;
						left: 0;
						width: 0%;
						background: theme-color('secondary') !important;
						transition: 0.2s;
					}
				}
			}
		}
	}

	.slider_container {
		.swiper-slider-milestone {
			padding-top: 150px;
			position: relative;

			.swiper-slide {
				margin: auto;
				.textbox {
					margin: auto;

					h5 {
						font-family: $font-family-gilroy-semiBold;
						margin-bottom: 20px;
					}

					h2 {
						margin-bottom: 50px;
					}

					h1,
					h2,
					h3,
					h4,
					h5,
					p {
						color: theme-color('accent-color') !important;
					}

					@include media-breakpoint-up(md) {
						h5 {
							font-size: $font-size-690;
							margin-bottom: 50px;
						}
					}

				}
			}

			.swiper-pagination {
				position: absolute;
				display: flex;
				top: 30px;
				height: 50px;
				width: 70%;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
				align-items: center;

				@include media-breakpoint-down (sm) {
					justify-content: center;
				}

				.swiper-pagination-bullet {
					position: absolute;
					background: theme-color('primary');
					width: 32px;
					height: 32px;
					opacity: 1;

					p {
						margin-left: -20px;
						position: absolute;
						font-size: $font-size-400;
						bottom: 0;

						@include media-breakpoint-up(lg) {
							bottom: 14px;
						}

						@include media-breakpoint-down(sm) {
							display: none !important;
						}
					}

					@include media-breakpoint-down(md) {
						height: 20px;
						width: 20px;
					}

					@include media-breakpoint-down(sm) {
						height: 8px;
						width: 8px;
						position: initial;
					}
				}

				.swiper-pagination-bullet-active {
					background: theme-color('secondary');
					height: 48px;
					width: 48px;

					p {
						bottom: 13px !important;

						@include media-breakpoint-up(lg) {
							bottom: 30px !important;
						}
					}

					@include media-breakpoint-down(md) {
						height: 32px;
						width: 32px;
					}

					@include media-breakpoint-down(sm) {
						height: 20px;
						width: 20px;
					}

				}
			}

			.bottom {
				p {
					bottom: -50px !important;
				}

				&.swiper-pagination-bullet-active {
					p {
						bottom: -60px !important;
					}
				}
			}

			.green_line {
				position: absolute;
				top: 53px;
				background-color: theme-color('primary');
				height: 4px;
				width: 70%;
				left: 0;
				right: 0;
				margin: auto;

				@include media-breakpoint-down (sm) {
					width: 90%;
				}

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
		}

		.swiper-controls-container {
			position: absolute;
			display: flex;
			justify-content: space-between;
			width: 90%;
			top: 35px;
			left: 0px;
			right: 0px;
			margin: auto;

			@include media-breakpoint-down(sm) {
				top: 35px;
				width: 100%;
				display:none;
			}

			@include media-breakpoint-down(xxs) {
				top: 85px;
				width: 50%;
			}

			.swiper_btn {
				display: inline-block;

				img {
					width: 50%;
				}
			}

			.swiper_btn_next {
				text-align: right;
			}
		}
	}
}