.herowhite-container {
	display: flex;
	justify-content: center;
	flex-flow: column;
	position: relative;
	background-color: theme-color('section-grey');

	.background-image {
		position: absolute;
		top: 150px;
		bottom: 0;
		left: 0;
		right: 0;

		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		@include media-breakpoint-down(sm) {
			display: none  !important;
		}
		&.image-desktop {
			display: block;

		}
	}

	@media (max-width:900px) {
		#changable_heroimage {
			display: none;
		}

		#changable_heroimage_mobile {
			display: flex;
			object-fit: cover;
		}
	}

	.text_container {
		margin-top: 320px;

		@include media-breakpoint-down(sm) {
			margin-top: 200px;
		}
		.hero_title {
			margin-bottom: 20px;
			margin: auto;
			letter-spacing: 0.75px;

			h1 {
				text-align: center;
				font-size: $font-size-900;
				line-height: $spacing-800;
				font-family: $font-family-freightText;
				font-weight: $font-weight-light;
				letter-spacing: 1.5px;
				@include media-breakpoint-down(sm) {
					font-size: $font-size-700;
					line-height: $spacing-450;
				}
			}
		}

		.hero_subtitle {
			font-size: 18px;
			color: $white;
			letter-spacing: 0.22px;
			text-align: left;
			line-height: 28px;

			p {
				color: theme-color('secondary');
				line-height: 26px;

				@media (max-width: 400px) {
					margin: 0;
					line-height: 18px;
					font-size: $font-size-400;
				}
			}
		}

		.row {
			flex-flow: column;
		}
	}

	.button_container {
		padding-top: 50px;

		a {
			width: 400px;
			max-width: 400px;
			@include media-breakpoint-down(xs) {
				width: 220px;
			}
		}
	}
}
#hero_section_cut {
	z-index: 9;
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: flex-end;
}