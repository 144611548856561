/** Import Bootstrap functions */
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url("//hello.myfonts.net/count/37cb39");
@import url("//hello.myfonts.net/count/3903e7");

  
@font-face {font-family: 'FreightTextProBold-Regular';src: url('../../assets/fonts/3903E7_0_0.eot');src: url('../../assets/fonts/3903E7_0_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/3903E7_0_0.woff2') format('woff2'),url('../../assets/fonts/3903E7_0_0.woff') format('woff'),url('../../assets/fonts/3903E7_0_0.ttf') format('truetype'),url('../../assets/fonts/3903E7_0_0.svg#wf') format('svg');}
 


@font-face {font-family: 'Gilroy-Thin';src: url('../../assets/fonts/37CB39_0_0.eot');src: url('../../assets/fonts/37CB39_0_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_0_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_0_0.woff') format('woff'),url('../../assets/fonts/37CB39_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-UltraLightItalic';src: url('../../assets/fonts/37CB39_1_0.eot');src: url('../../assets/fonts/37CB39_1_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_1_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_1_0.woff') format('woff'),url('../../assets/fonts/37CB39_1_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-ThinItalic';src: url('../../assets/fonts/37CB39_2_0.eot');src: url('../../assets/fonts/37CB39_2_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_2_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_2_0.woff') format('woff'),url('../../assets/fonts/37CB39_2_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-SemiBoldItalic';src: url('../../assets/fonts/37CB39_3_0.eot');src: url('../../assets/fonts/37CB39_3_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_3_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_3_0.woff') format('woff'),url('../../assets/fonts/37CB39_3_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-SemiBold';src: url('../../assets/fonts/37CB39_4_0.eot');src: url('../../assets/fonts/37CB39_4_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_4_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_4_0.woff') format('woff'),url('../../assets/fonts/37CB39_4_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-UltraLight';src: url('../../assets/fonts/37CB39_5_0.eot');src: url('../../assets/fonts/37CB39_5_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_5_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_5_0.woff') format('woff'),url('../../assets/fonts/37CB39_5_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-Regular';src: url('../../assets/fonts/37CB39_6_0.eot');src: url('../../assets/fonts/37CB39_6_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_6_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_6_0.woff') format('woff'),url('../../assets/fonts/37CB39_6_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-RegularItalic';src: url('../../assets/fonts/37CB39_7_0.eot');src: url('../../assets/fonts/37CB39_7_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_7_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_7_0.woff') format('woff'),url('../../assets/fonts/37CB39_7_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-MediumItalic';src: url('../../assets/fonts/37CB39_8_0.eot');src: url('../../assets/fonts/37CB39_8_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_8_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_8_0.woff') format('woff'),url('../../assets/fonts/37CB39_8_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-Medium';src: url('../../assets/fonts/37CB39_9_0.eot');src: url('../../assets/fonts/37CB39_9_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_9_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_9_0.woff') format('woff'),url('../../assets/fonts/37CB39_9_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-LightItalic';src: url('../../assets/fonts/37CB39_A_0.eot');src: url('../../assets/fonts/37CB39_A_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_A_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_A_0.woff') format('woff'),url('../../assets/fonts/37CB39_A_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-Light';src: url('../../assets/fonts/37CB39_B_0.eot');src: url('../../assets/fonts/37CB39_B_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_B_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_B_0.woff') format('woff'),url('../../assets/fonts/37CB39_B_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-HeavyItalic';src: url('../../assets/fonts/37CB39_C_0.eot');src: url('../../assets/fonts/37CB39_C_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_C_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_C_0.woff') format('woff'),url('../../assets/fonts/37CB39_C_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-Heavy';src: url('../../assets/fonts/37CB39_D_0.eot');src: url('../../assets/fonts/37CB39_D_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_D_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_D_0.woff') format('woff'),url('../../assets/fonts/37CB39_D_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-ExtraBoldItalic';src: url('../../assets/fonts/37CB39_E_0.eot');src: url('../../assets/fonts/37CB39_E_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_E_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_E_0.woff') format('woff'),url('../../assets/fonts/37CB39_E_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-ExtraBold';src: url('../../assets/fonts/37CB39_F_0.eot');src: url('../../assets/fonts/37CB39_F_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_F_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_F_0.woff') format('woff'),url('../../assets/fonts/37CB39_F_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-BoldItalic';src: url('../../assets/fonts/37CB39_10_0.eot');src: url('../../assets/fonts/37CB39_10_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_10_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_10_0.woff') format('woff'),url('../../assets/fonts/37CB39_10_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-Bold';src: url('../../assets/fonts/37CB39_11_0.eot');src: url('../../assets/fonts/37CB39_11_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_11_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_11_0.woff') format('woff'),url('../../assets/fonts/37CB39_11_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-BlackItalic';src: url('../../assets/fonts/37CB39_12_0.eot');src: url('../../assets/fonts/37CB39_12_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_12_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_12_0.woff') format('woff'),url('../../assets/fonts/37CB39_12_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Gilroy-Black';src: url('../../assets/fonts/37CB39_13_0.eot');src: url('../../assets/fonts/37CB39_13_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/37CB39_13_0.woff2') format('woff2'),url('../../assets/fonts/37CB39_13_0.woff') format('woff'),url('../../assets/fonts/37CB39_13_0.ttf') format('truetype');}

$theme-colors: (
    primary: #01D8C3,
    secondary: #1F497D,
    white: #FFFFFF,
    black: #000000,
    accent-color: #0F243E ,
    link-color: #BD10E0,
    hover-color:  #787878,
    paragraph-color: #1E1E1E,
	section-grey: #F4F6FA,
	blue-tone: #1F497D,
);

$container-max-widths: (
    xs: 540px,
    sm: 720px,
    md: 960px,
    lg: 1170px,
    xl: 1440px
);

$grid-breakpoints: (
    xxxs: 0,
    xxs: 360px,
    // Extra small screen / phone
    xs: 576px,
    // Small screen / phone
    sm: 768px,
    // Medium screen / tablet
    md: 992px,
    // Large screen / desktop
    lg: 1200px,
    // Extra large screen / wide desktop
    xl: 1470px
);

@function get-breakpoints($key: 'md') {
    @return map-get($container-max-widths, $key);
}

$font-size-200:               12px;
$font-size-300:               14px;
$font-size-400:               16px;
$font-size-450:				  18px;
$font-size-500:               20px;
$font-size-600:               24px;
$font-size-650:				  26px;
$font-size-680:				  28px;
$font-size-690:				  36px;
$font-size-700:               40px;
$font-size-800:               48px;
$font-size-850:				  52px;
$font-size-900:               64px;
$font-size-1000:              75px;
$font-size-1100:              100px;
$font-size-1200:			  120px;

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-bold:            700;

$h1-font-size:                96px;
$h2-font-size:                48px;
$h3-font-size:                24px;
$h4-font-size:                24px;
$h5-font-size:                20px;

$h1-font-size-mobile:                52px;
$h2-font-size-mobile:                40px;
$h3-font-size-mobile:                24px;
$h4-font-size-mobile:                24px;
$h5-font-size-mobile:                20px;

$spacing-regular:   32px;
$spacing-h5:        24px;
$spacing-h4:        28px;
$spacing-h3:        36px;
$spacing-h2:        60px;
$spacing-h2-mobile: 40px;
$spacing-h1:        120px;
$spacing-h1-mobile: 60px;

$spacing-100: 10px;
$spacing-150: 14px;
$spacing-200: 24px;
$spacing-250: 26px;
$spacing-280: 28px;
$spacing-300: 30px;
$spacing-320: 32px;
$spacing-400: 40px;
$spacing-450: 48px;
$spacing-500: 50px;
$spacing-600: 60px;
$spacing-650: 64px;
$spacing-700: 70px;
$spacing-800: 80px;
$spacing-900: 90px;

$font-family-freightText:				'FreightTextProBold-Regular','sans-serif';
$font-family-roboto:                    'Roboto', 'sans-serif';
$font-family-roboto-bold:               'Roboto-Bold','sans-serif';
$font-family-gilroy-thin:               'Gilroy-Thin', 'sans-serif';
$font-family-gilroy-ultraLightItalic:   'Gilroy-UltraLightItalic','sans-serif'; 
$font-family-gilroy-thinItalic:         'Gilroy-ThinItalic', 'sans-serif';
$font-family-gilroy-semiBoldItalic:     'Gilroy-SemiBoldItalic','sans-serif';
$font-family-gilroy-semiBold:           'Gilroy-SemiBold','sans-serif';
$font-family-gilroy-ultraLight:         'Gilroy-UltraLight','sans-serif';
$font-family-gilroy-regular:            'Gilroy-Regular','sans-serif';
$font-family-gilroy-regularItalic:      'Gilroy-RegularItalic','sans-serif';
$font-family-gilroy-mediumItalic:       'Gilroy-MediumItalic','sans-serif';
$font-family-gilroy-medium:             'Gilroy-Medium','sans-serif';
$font-family-gilroy-lightItalic:        'Gilroy-LightItalic','sans-serif';
$font-family-gilroy-light:              'Gilroy-Light','sans-serif';
$font-family-gilroy-heavyItalic:        'Gilroy-HeavyItalic','sans-serif';
$font-family-gilroy-heavy:              'Gilroy-Heavy','sans-serif';
$font-family-gilroy-extraBoldItalic:    'Gilroy-ExtraBoldItalic','sans-serif';
$font-family-gilroy-extraBold:          'Gilroy-ExtraBold','sans-serif'; 
$font-family-gilroy-boldItalic:         'Gilroy-BoldItalic','sans-serif';
$font-family-gilroy-bold:               'Gilroy-Bold','sans-serif';
$font-family-gilroy-blackItalic:        'Gilroy-BlackItalic','sans-serif';
$font-family-gilroy-black:              'Gilroy-Black','sans-serif';
