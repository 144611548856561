.heading_image-container {

	.heading_image-title,
	.heading_image-subtitle,
	.heading_image-image {
		margin: auto;
	}

	.heading_image-title {
		h1 {
			margin-bottom: 30px;
		}

		@include media-breakpoint-down(sm) {
			padding-top: 50px;
			h1 {
				font-size: $font-size-700;
			}
		}
	}

	.heading_image-subtitle {
		p {
			text-align: center;
		}
		margin-bottom: 50px;
		@include media-breakpoint-down(xs) {
			margin-bottom: 25px;
		}
	}
	.hero_button {
		.button_container {
			padding-top: 40px;
			padding-bottom: 40px;
			@include media-breakpoint-down(xs) {
				padding-top: 20px;
				padding-bottom: 20px;
			}
			.btn-primary {
				max-width: 400px !important;
			}
		}
	}
}