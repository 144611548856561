.header {
	top: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	position: fixed;
	z-index: 9999;
	transition: all 200ms ease-in-out;

	@media (max-width: 360px) {
		.container {
			padding-right: 0;
			padding-left: 0;

			.container-fluid {
				.header_menu {
					margin: auto;
				}
			}
		}

	}
	@media (min-width: 361px) and (max-width: 991px){
		.navbar {
			padding-left: unset;
			
		}
	}
	.header_menu {
		display: flex;
		width: 100%;
		justify-content: space-between;
		@include media-breakpoint-down(sm) {
			.navbar-collapse {
				padding-left: 0;

			}
		}
		.navbar-brand {
			margin-right: unset;
			display: flex;
			justify-content: flex-start;
			padding-top: 0;

			@include media-breakpoint-down(sm) {
				justify-content: flex-start;

			}

			@include media-breakpoint-down(xs) {
				width: 70%;
			}

			&:after {
				display: none;
			}
		}

		.navbar-toggler {
			display: flex;
			justify-content: flex-end;
			padding:unset;

			@include media-breakpoint-up(md) {
				display: none;
				visibility: hidden;
				opacity: 0;
				transition: visibility 0s, opacity 0.5s linear;

			}

			.header_burger,
			.header_close {
				display: none;
				visibility: hidden;
				opacity: 0;
				transition: visibility 0s, opacity 0.5s linear;

			}

			img {
				width: 50%;
			}

		}

		i {
			color: $white;
			-webkit-transition: all 200ms ease-in-out;
			-moz-transition: all 200ms ease-in-out;
			-o-transition: all 200ms ease-in-out;
			transition: all 200ms ease-in-out;
		}

		.header_logo {
			text-align: left;
			align-self: center;

			@include media-breakpoint-up(lg) {
				max-width: 50%;
			}

			@include media-breakpoint-down(sm) {
				display: inline-block;

				img {
					width: 50%
				}
			}

		}
	}

	#primaryMobile {
		display: flex;
		width: 100%;
		justify-content: space-around;

		@include media-breakpoint-down(sm) {
			position: absolute;
			left: 0px;
			bottom: 100px;
			height: auto;
			justify-content: space-evenly;
		}

		.active {
			a {
				&:after {
					height: 3px;
					width: 100%;
				}
			}
		}
		.dropdown {
			height: auto;
		}
		li {
			font-size: 14px;
			display: flex;
			flex-flow: column;
			justify-content: center;

			a {
				font-family: $font-family-gilroy-semiBold;
				letter-spacing: 1px;
				text-transform: uppercase;

				&,
				&:visited {
					text-decoration: none;
					position: relative;
					color: $white;
				}

				&:hover:after,
				&:visited:hover:after {
					width: 100%;
				}

				&:after,
				&:visited:after {
					content: '';
					height: 3px;
					position: absolute;
					bottom: -5px;
					left: 0;
					width: 0%;
					background: $white;
					transition: 0.2s;
				}

				@include media-breakpoint-down(sm) {
					font-size: $h4-font-size;
					line-height: $spacing-500;
				}
			}
		}

		.bewerbung-menu {
			@include media-breakpoint-up(md) {
				border: 2px solid theme-color('primary');
				padding: 10px 25px;
				border-radius: 100px;
				padding-left: 40px !important;
				padding-right: 40px !important;

			}

			color: theme-color('primary') !important;

			&:after,
			&:visited:after {
				height: 0px;
			}

			@include media-breakpoint-down(sm) {

				&,
				&:visited {
					text-decoration: none;
					position: relative;
					color: theme-color('primary');
				}

				&:hover:after,
				&:visited:hover:after {
					width: 100%;
				}

				&:after,
				&:visited:after {
					content: '';
					height: 3px;
					position: absolute;
					bottom: -5px;
					left: 0;
					width: 0%;
					background: theme-color('primary');
					transition: 0.2s;
				}
			}

			&:after {
				display: none;
			}
		}

		.language-switcher {
			display: flex;

			.menu-item {
				a {
					color: theme-color('primary');
				}

				.current {
					color: $white;
				}
			}

			.menu-item:nth-child(1) {
				padding-right: 15px;
				position: relative;
				display: flex;

				&:after {
					position: absolute;
					background: $white;
					right: 0;
					width: 2px;
					margin-right: 7.5px;
					height: 20px;
					content: "";
				}
			}

			a {
				&::after {
					display: none;
				}
			}
		}
		.show {
			display: flex;
		}
		.dropdown-menu {
			top: 127%;
			left: -266px;
			opacity: 0.9;
			border-radius: 24px;
			height: auto;
			@include media-breakpoint-down(sm) {
				top: unset;
				left: unset;
				opacity: unset;
				border-radius: unset;
				border: unset;

			}
			
			li {
				display: inline-block;
				width: 300px;
				padding: 20px;
				text-align: center;
				@include media-breakpoint-down(sm) {
					padding: unset;
				}

				a {
					display: flex;
					flex-flow:column;
					img {
						align-self: center;
						@include media-breakpoint-down(sm) {
							display: none; 
						}
					}
					span {
						font-family: $font-family-gilroy-semiBold;
						font-size: $font-size-650;
						color: theme-color('accent-color');
						line-height: $spacing-320;
						padding-bottom: 5px;
						@include media-breakpoint-down(sm) {
							text-align: left;
							padding-left: 20px;
							
						}
					}
					p {
						font-family: $font-family-gilroy-regular;
						font-size: $font-size-400;
						color: theme-color('accent-color');
						letter-spacing: 0.2px;
						line-height: $spacing-200;
						@include media-breakpoint-down(sm) {
							display: none;
						}
					}
					@include media-breakpoint-down(sm) {
						&:after {
							display: none;
						}
					}

				}
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.show {
		height: 100vh;
		display: flex;
		flex-flow: column;
		justify-content: flex-end;

		#primaryMobile {
			position: absolute;
			left: 0px;
			bottom: 100px;
			height: auto;
			justify-content: space-evenly;
		}
	}
}

.header_font_inverted {
	a {
		color: theme-color('accent-color') !important;
		-webkit-transition: all 200ms ease-in-out;
		-moz-transition: all 200ms ease-in-out;
		-o-transition: all 200ms ease-in-out;
		transition: all 200ms ease-in-out;

		&,
		&:visited {
			text-decoration: none;
			position: relative;
			color: theme-color('accent-color');
		}

		&:hover:after,
		&:visited:hover:after {
			width: 100%;
		}

		&:after,
		&:visited:after {
			content: '';
			height: 3px;
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 0%;
			background: theme-color('secondary') !important;
			transition: 0.2s;
		}
	}

	.language-switcher {
		display: flex;

		.menu-item {
			a {
				color: theme-color('primary') !important;
			}

			.current {
				color: theme-color('accent-color') !important;
			}
		}

		.menu-item:nth-child(1) {
			&:after {
				background: theme-color('accent-color') !important;
			}
		}
	}
}

.scrolled {
	background-color: $white !important;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;

	a {
		color: theme-color('accent-color') !important;
		-webkit-transition: all 200ms ease-in-out;
		-moz-transition: all 200ms ease-in-out;
		-o-transition: all 200ms ease-in-out;
		transition: all 200ms ease-in-out;

		&,
		&:visited {
			text-decoration: none;
			position: relative;
			color: theme-color('accent-color');
		}

		&:hover:after,
		&:visited:hover:after {
			width: 100%;
		}

		&:after,
		&:visited:after {
			content: '';
			height: 3px;
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 0%;
			background: theme-color('secondary') !important;
			transition: 0.2s;
		}
	}

	.language-switcher {
		display: flex;

		.menu-item {
			a {
				color: theme-color('primary') !important;
			}

			.current {
				color: theme-color('accent-color') !important;
			}
		}

		.menu-item:nth-child(1) {
			&:after {
				background: theme-color('accent-color') !important;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.scrolled_button {
		background-color: $white !important;
		-webkit-transition: all 200ms ease-in-out;
		-moz-transition: all 200ms ease-in-out;
		-o-transition: all 200ms ease-in-out;
		transition: all 200ms ease-in-out;

		a {
			color: theme-color('accent-color') !important;
			-webkit-transition: all 200ms ease-in-out;
			-moz-transition: all 200ms ease-in-out;
			-o-transition: all 200ms ease-in-out;
			transition: all 200ms ease-in-out;

			&,
			&:visited {
				text-decoration: none;
				position: relative;
				color: theme-color('accent-color');
			}

			&:hover:after,
			&:visited:hover:after {
				width: 100%;
			}

			&:after,
			&:visited:after {
				content: '';
				height: 3px;
				position: absolute;
				bottom: -5px;
				left: 0;
				width: 0%;
				background: theme-color('secondary') !important;
				transition: 0.2s;
			}
		}
	}

	.language-switcher {
		display: flex;

		.menu-item {
			a {
				color: theme-color('primary') !important;
			}

			.current {
				color: theme-color('accent-color') !important;
			}
		}

		.menu-item:nth-child(1) {
			&:after {
				background: theme-color('accent-color') !important;
			}
		}
	}
}

.scrolled_acf {
	background-color: $white !important;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;

	a {
		color: theme-color('accent-color') !important;
		-webkit-transition: all 200ms ease-in-out;
		-moz-transition: all 200ms ease-in-out;
		-o-transition: all 200ms ease-in-out;
		transition: all 200ms ease-in-out;

		&,
		&:visited {
			text-decoration: none;
			position: relative;
			color: theme-color('accent-color');
		}

		&:hover:after,
		&:visited:hover:after {
			width: 100%;
		}

		&:after,
		&:visited:after {
			content: '';
			height: 3px;
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 0%;
			background: theme-color('secondary') !important;
			transition: 0.2s;
		}
	}
}

.logo_inverted {
	display: none;
}

.show {
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
	-webkit-animation: fadein 1s;
	/* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s;
	/* Firefox < 16 */
	-ms-animation: fadein 1s;
	/* Internet Explorer */
	-o-animation: fadein 1s;
	/* Opera < 12.1 */
	animation: fadein 1s;
}

.show_logo {
	#logo_header {
		display: none !important;
	}

	#logo_header_inverted {
		display: block !important;
	}
}

@include media-breakpoint-down(sm) {
	.show_logo_button {
		#logo_header {
			display: none !important;
		}

		#logo_header_inverted {
			display: block !important;
		}
	}
}

.show_logo_acf {
	#logo_header {
		display: none !important;
	}

	#logo_header_inverted {
		display: block !important;
	}
}

.show_button {
	.header_burger {
		display: block !important;
		visibility: initial !important;
		opacity: 1 !important;
	}

	.header_close,
	.header_burger_inverted {
		display: none;
	}
}

@include media-breakpoint-down(sm) {
	.show_button_button {
		.header_close {
			display: block !important;
			visibility: initial !important;
			opacity: 1 !important;
			transition: visibility 0s, opacity 0.5s linear !important;

		}

		.header_burger,
		.header_burger_inverted {
			display: none !important;
		}

		&.show_button_acf {

			.header_burger_inverted,
			.header_burger {
				display: none !important;
			}
		}
	}
}

.show_button_acf {
	.header_burger {
		display: block !important;
		visibility: initial !important;
		opacity: 1 !important;
	}

	.header_burger_inverted,
	.header_close {
		display: none;
	}
}

.hide {
	display: none !important;
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
}

@include media-breakpoint-down(sm) {
	.header {
		.header_menu {
			margin: auto;
			.navbar-brand {
				text-align: left;
				padding-bottom: 0px;
			}
		}
	}
}