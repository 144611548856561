.person_box-container {
	padding-bottom: 0 !important;
	.person_box-container {
		.repeater_heading {
			margin: auto;
			text-align: center;
			margin-bottom: 160px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 80px;
			}
		}

		.person_box {
			text-align: center;
			margin-bottom: 160px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 80px;
			}

			.person_box-image {
				display: inline-block;
				justify-content: center;
				padding-bottom: 25px;

				img {
					width: 50%;
				}
			}

			.textbox {
				margin: auto;
				text-align: center;

				@media (max-width: 430px) {
					padding: unset;
				}

				p {
					margin-bottom: 50px;
					@include media-breakpoint-down(sm) {
						margin-bottom: 25px;
					}

					strong {
						font-family: $font-family-gilroy-bold;
						color: theme-color('accent-color');
						text-align: center;
						line-height: $spacing-280;
						letter-spacing: 1px;

						@include media-breakpoint-down(xs) {
							font-size: $font-size-450;
						}
					}

					em {
						font-style: normal;
						font-family: $font-family-gilroy-regularItalic;
						font-size: $font-size-680;
						letter-spacing: 0.6px;
						line-height: $spacing-400;

						@include media-breakpoint-down(xs) {
							font-size: $font-size-450;
							line-height: $spacing-320;
						}
					}

				}
			}
		}

		.icon_box {
			margin-top: 80px;
			text-align: center;

			h4 {
				color: theme-color('accent-color');
				font-size: $font-size-680;
				line-height: $spacing-450;
				text-align: center;
				@include media-breakpoint-down(sm) {
					line-height: $spacing-h3;
				}
			}

			.icon_image {
				display: inline-block;
				margin: auto;

				img {
					width: 50%;
					margin: auto;

					@include media-breakpoint-down(xs) {
						width: 70%;
					}
				}
			}
		}
		.button-personbox {
			.button_container {
				padding-bottom: 0;
				.btn {
				max-width: 400px !important;
				}
			}
			
		}
		.background_image {
			display: inline-block;
			position: absolute;
			top: 0;
			left: 100px;
			right: 100px;
			text-align: center;
			z-index: -999;

			img {
				width: 50%;
				max-width: 280px;
			}

			@include media-breakpoint-down(xs) {
				left: 0;
				right: 0;
			}
		}
	}
}