.repeater_inline_heading-container {
	.repeater_content {
		display: flex;
		flex-wrap: wrap;

		.repeater_heading {
			display: flex;
			flex-flow: column;
			justify-content: center;
			float: left;
			padding-bottom: 25px;

			h2,
			p {
				text-align: left;
			}

			p {
				margin-top: 10px;
				max-width: 90%;
			}

			@include media-breakpoint-down(xs) {
				h2 {
					font-size: $font-size-690;
					line-height: $spacing-450;
				}
			}
		}

		.textbox {
			padding-bottom: 50px;

			img {
				width: 50%;
				padding-bottom: 20px;
			}

			p {
				padding-top: 30px;

				@include media-breakpoint-down(sm) {
					padding-top: 15px;
				}
			}
			@include media-breakpoint-down(xs) {
				padding-bottom: 20px;
			}
			.image_textbox {
				display: inline-block;
				@include media-breakpoint-down(sm) {
					img {
						padding-bottom:unset;
					}
				}
			}
		}
	}
}

.product-center {
	.repeater_heading {
		p {
			margin: auto;
			text-align: center !important;
		}
	}

	.textbox {
		margin: auto;
	}
}

.repeater-product {
	background-color: theme-color('section-grey');

	.repeater_content {

		.repeater_heading.col-md-8,
		.repeater_heading.col-sm-10 {
			flex: unset;
			max-width: unset;
			-ms-flex: unset;
			-webkit-box-flex: unset;

			h2 {
				text-align: center;
			}
		}
	}
}

.heading_top {
	background-color: theme-color('section-grey');

	.repeater_content {

		.repeater_heading.col-md-8,
		.repeater_heading.col-sm-10 {
			flex: unset;
			max-width: unset;
			-ms-flex: unset;
			-webkit-box-flex: unset;

			h2 {
				text-align: center;
			}
		}
	}
}

.text-align-center {
	text-align: center;
}