@import "common/variables";


/** Import everything from autoload */
@import "autoload/bootstrap";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

/** Import theme styles */
@import "common/global";
@import "components/wp-classes";
@import "components/cookie-bar";
@import "components/contact-modal";
@import "layouts/call-to-action";
@import "layouts/footer";
@import "layouts/header";
@import "layouts/blue-banner";
@import "layouts/feature-slider";
@import "layouts/heading-image";
@import "layouts/hero-banner";
@import "layouts/milestone-repeater";
@import "layouts/person-box";
@import "layouts/publication-repeater";
@import "layouts/repeater-inline-heading";
@import "layouts/team-repeater";
@import "layouts/three-icon-block";
@import "layouts/textbox";
@import "layouts/section-cut";
@import "layouts/reference-repeater";
@import "layouts/image-block";
@import "layouts/hero-white";
@import "layouts/blue-box";
@import "layouts/margin-section";
@import "layouts/bewerbung-repeater";
@import "layouts/dataprivacy-impressum";
@import "layouts/job-repeater";
@import "layouts/two-icon-block";
@import "layouts/textblock";
