.blue_box {
	position: relative;

	.box {
		border-radius: 80px;
		padding: 60px;
		display: flex;
		flex-flow: column;
		background-color: theme-color('secondary');

		@include media-breakpoint-down(xxs) {
			padding: 0;
			padding-top: 50px;
			padding-bottom: 50px;
			border-radius: 0;
		}

		.hero_button {
			.button_container {
				display: contents;
				padding-top: unset;
				padding-left: unset;
				padding-bottom: unset;

				div {
					padding: unset;
				}
				@include media-breakpoint-up (md) {
					.cta {
						a {
							margin: unset;
						}
					}
				}

			}
		}

		.content {
			display: flex;
			flex-flow: row;

		}

		.text_button {
			padding-left: unset;

			.text {
				h2 {
					color: $white;
					padding-bottom: 8px;

					@include media-breakpoint-down(xs) {
						font-size: $font-size-690;
						line-height: $spacing-450;
					}
				}

				p {
					color: $white;
					padding-bottom: 15px;
				}

				@include media-breakpoint-down(sm) {
					text-align: center;
				}
			}
		}
	}

	.image {
		position: absolute;
		display: inline-block;
		text-align: right;
		bottom: 0;
		right: 6vw;
		max-width: 480px;
		@include media-breakpoint-down(md) {
			max-width: 440px;
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}