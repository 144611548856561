.button_container {
	padding-top: 80px;
	padding-bottom: 80px;

	@include media-breakpoint-down(sm) {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.btn-primary {
		display: flex;
		justify-content: center;
		flex-flow: column;
		max-width: 320px;
		width: 100%;
		height: 64px;
		border-radius: 30px;
		padding: 10px;
		padding-top: 12px;
		text-transform: uppercase;
		margin: auto;
		font-family: $font-family-gilroy-semiBold;
		letter-spacing: 0.8px;
		@include media-breakpoint-down(xs) {
			width: 220px;
			height: 44px;
			font-size: $font-size-200;
		}
	}
}