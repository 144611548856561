.job-repeater {
	.job_content {

		flex-flow: column;

		.job_title {
			padding-bottom: 120px;

			@include media-breakpoint-down(xs) {
				padding-bottom: 60px;
				text-align: left;
			}
		}

		.job_row {
			display: flex;
			padding-bottom: 80px;
			flex-wrap: wrap;

			@include media-breakpoint-down(xs) {
				padding-bottom: 40px;
			}

			.left-content {
				padding-left: unset;
				h3,h4,h5 {
					padding-bottom: 60px;
					@include media-breakpoint-down(xs) {
						padding-bottom: 30px;
						text-align: left;
					}
				}
			}

			.right-content {
				ul {
					padding-left: 20px;

					li {
						font-size: $font-size-500;
						font-family: $font-family-gilroy-regular;
						color: theme-color('accent-color');
						line-height: $spacing-320;
						@include media-breakpoint-down(xs) {
							font-size: $font-size-300;
							line-height: $spacing-200;
						}
					}
				}
				a {
					font-size: $font-size-500;
					line-height: $spacing-320;
					span {
						padding-left: 30px;
						display: inline-block;
						img {
							width: 50%;
						}
					}  
				}
				p {
					@include media-breakpoint-down(xs) {
						font-size: $font-size-300;
						line-height: $spacing-200;
					}
				}
				.arrow_icon_right {
					@include media-breakpoint-down(xs) {
						width: 60px;
						padding-left: 10px;
					} 
				}
			}
			
		}
		.last-row {
			.right-content {
				@include media-breakpoint-down(xs) {
					text-align: left;
				}
			}
		}
	}
}