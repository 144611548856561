.feature_slider {
	.title_container {
		.title {
			margin: auto;
		}

		h2 {
			text-align: center;
			padding-bottom: 25px;

			@include media-breakpoint-down(sm) {
				padding-bottom: 12.5px;
			}
		}

		p {
			text-align: center;
		}
	}

	.slider_container {
		.feature_container {
			padding-bottom: 100px;

			.swiper-container {
				padding-bottom: 25px;
				.swiper-pagination {
					position: absolute;
					width: 100%;
					height: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					top: 0;
					@include media-breakpoint-up(md) {
						display:none; 
					}
					.swiper-pagination-bullet {
						position: absolute;
						background: theme-color('primary');
						width: 32px;
						height: 32px;
						opacity: 1;
	
						p {
							margin-left: -20px;
							position: absolute;
							font-size: $font-size-400;
							bottom: 0;
	
							@include media-breakpoint-up(lg) {
								bottom: 14px;
							}
	
							@include media-breakpoint-down(sm) {
								display: none !important;
							}
						}
	
						@include media-breakpoint-down(md) {
							height: 20px;
							width: 20px;
						}
	
						@include media-breakpoint-down(sm) {
							height: 8px;
							width: 8px;
							position: initial;
						}
					}
	
					.swiper-pagination-bullet-active {
						background: theme-color('secondary');
						height: 48px;
						width: 48px;
	
						p {
							bottom: 13px !important;
	
							@include media-breakpoint-up(lg) {
								bottom: 30px !important;
							}
						}
	
						@include media-breakpoint-down(md) {
							height: 32px;
							width: 32px;
						}
	
						@include media-breakpoint-down(sm) {
							height: 20px;
							width: 20px;
						}
	
					}
				}
				.swiper_controls {
					@include media-breakpoint-down(sm) {
						display: none;
						justify-content: center;
						position: absolute;
						left: 0;
						right: 0;
						top: 430px;
					}

					@include media-breakpoint-down(xs) {
						top: 330px;
					}

					@include media-breakpoint-down(xxs) {
						top: 58vw;
					}
				}

				.swiper-controls-container {
					position: relative;
					width: 300px;

					.swiper_arrows {
						margin: auto;
					}

					.swiper-button-next,
					.swiper-button-prev {
						background-image: none;
						width: auto;

						img {
							width: 50%;
						}

						@media (max-width: 350px) {
							width: 100px;
						}
					}

					.swiper-button-next {
						text-align: right;
					}
				}

				.swiper-wrapper {
					.swiper-slide {
						.featurebox {
							display: flex;
							flex-wrap: wrap;

							.image {
								@include media-breakpoint-down(xs) {
									padding-bottom: 25px;
								}
							}

							.logo_text {
								display: flex;
								flex-flow: column;
								justify-content: center;

								.logo {
									display: -webkit-box;

									img {
										width: 50%;
									}

									@include media-breakpoint-down(sm) {
										display: none;
									}
								}

								.text {
									h3 {
										padding-bottom: 20px;
									}
									p {
										text-align: center;
									}
									@include media-breakpoint-down(sm) {
										h3 {
											text-align: center;
										}
									}
								}

								@include media-breakpoint-down(sm) {
									text-align: center;

									.text {
										text-align: left;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.product-slider {
	padding-top: 120px;
	padding-bottom: 120px;

	@include media-breakpoint-down(sm) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}