.hero-container {
	display: flex;
	justify-content: center;
	flex-flow: column;
	position: relative;


	.background-image {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		&.image-desktop {
			display: block;

		}

		@include media-breakpoint-down(xs) {
			background-color: theme-color('secondary');
			background-image: unset !important;
		}
	}

	@media (max-width:900px) {
		#changable_heroimage {
			display: none;
		}

		#changable_heroimage_mobile {
			display: flex;
			object-fit: cover;
		}
	}

	.text_container {
		margin-top: 320px;

		@include media-breakpoint-down(sm) {
			margin-top: 100px;
		}

		.hero_title {
			color: theme-color('white');
			margin-bottom: 50px;
			letter-spacing: 0.75px;
			@include media-breakpoint-down(xs) {
				margin-bottom: 20px;
			}
			h1 {
				text-align: left;
				color: $white;
				font-family: $font-family-freightText;
				font-weight: $font-weight-light;
				letter-spacing: 1.5px;
			}
		}

		.hero_subtitle {
			font-size: 18px;
			letter-spacing: 0.22px;
			text-align: left;
			line-height: 28px;

			p {
				color: $white;
				line-height: 36px;

				@include media-breakpoint-down(xs) {
					margin: 0;
					line-height: 28px;
					font-size: $font-size-300;
				}
			}
		}

		.row {
			flex-flow: column;
		}
	}

	.button_container {
		padding-top: 50px;
		.col-12 {
			padding-left: unset;
		}

		a {
			width: 400px;
			max-width: 400px;
			@include media-breakpoint-down(xs) {
				width: 220px;
			}
		}
	}

	.hero_button {
		margin-bottom: 240px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 120px;
		}

		@include media-breakpoint-down(xs) {
			margin-bottom: 60px;
		}

		.button_container {
			.cta {
				.btn-primary {
					margin: unset;
				}
			}
		}

	}

	#hero_section_cut {
		z-index: 9;
		position: absolute;
		bottom: 0;
		display: flex;
		justify-content: flex-end;
	}
}

.product-hero {
	background-color: theme-color('secondary');

	.text_container {

		.hero_title,
		.hero_subtitle {
			margin: auto;
			text-align: center;

			h1 {
				margin: auto;
				text-align: center;
				font-size: $font-size-900;
				line-height: $spacing-800;
				@include media-breakpoint-down(sm) {
					font-size: $font-size-700;
					line-height: $spacing-450;
				}
			}
		}

		.hero_title {
			padding-bottom: 50px;

			/* h1 {
				font-size: $h1-font-size-mobile;
				line-height: $spacing-h1-mobile;
				@include media-breakpoint-up(md) {
					font-size: $h1-font-size;
					line-height: $spacing-h1;
				}
			} */
			&.col-md-8 {
				max-width: unset;
			}
		}
	}

	.hero_button {
		.button_container {
			padding-top: 50px;
			padding-bottom: 0px;
		}

		.btn-primary {
			margin: auto !important;
		}
	}
}

.about-hero {

	.text_container {
		margin-top:460px;
		@include media-breakpoint-down(sm) {
			margin-top: 100px;
		}
		.hero_title,
		.hero_subtitle {
			margin: auto;
			text-align: center;
		}

		.hero_title {
			h1 {
				text-align: center;
				font-size: $font-size-900;
				line-height: $spacing-800;
				@include media-breakpoint-down(xs) {
					font-size: $font-size-700;
					line-height: $spacing-450;
				}
			}
			/* h1 {
				text-align: center;
				font-size: $h1-font-size-mobile;
				line-height: $spacing-h1-mobile;
				@include media-breakpoint-up(md) {
					font-size: $h1-font-size;
					line-height: $spacing-h1;
				}
			} */
			&.col-md-8 {
				max-width: unset;
			}
		}
	}

	.hero_button {
		.button_container {
			padding-top: 50px;
			padding-bottom: 0px;
		}

		.btn-primary {
			margin: auto !important;
		}
	}
}

.publication-hero {
	.background-image {
		background-size: contain;
		background-repeat: no-repeat;
		top: 200px;
		left: 10vw;
		@include media-breakpoint-down(sm) {
			background-color: theme-color('section-grey');
			background-image: unset !important;
		}
	}

	.text_container {

		.hero_title {
			h1 {
				color: theme-color('accent-color');
			}

			/* h1 {
				text-align: center;
				font-size: $h1-font-size-mobile;
				line-height: $spacing-h1-mobile;
				@include media-breakpoint-up(md) {
					font-size: $h1-font-size;
					line-height: $spacing-h1;
				}
			} */
		}
	}
	.hero_button {
		margin-bottom: 80px;
	}
}

.agb-hero {
	.background-image {
		background-color: theme-color('section-grey');
	}

	.text_container {

		.hero_title {
			h1 {
				color: theme-color('accent-color');
			}

			/* h1 {
				text-align: center;
				font-size: $h1-font-size-mobile;
				line-height: $spacing-h1-mobile;
				@include media-breakpoint-up(md) {
					font-size: $h1-font-size;
					line-height: $spacing-h1;
				}
			} */
		}
	}
	.hero_button {
		margin-bottom: 80px;
	}
}