.section_cut {
	z-index: 0;
	padding: 0;

	img {
		width: 100%;
	}

	.image {
		display: flex;
	}

	div {
		max-width: 100%;
	}
}

.only-mobile {
	@include media-breakpoint-up(xs) {
		display: none;
	}
}