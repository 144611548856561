.modal {
	position: fixed;
	z-index: 10000;
	/* 1 */
	top: 0;
	left: 0;
	visibility: hidden;
	width: 100%;
	height: 100%;
	overflow: unset;
	overflow-y:auto;

	.modal-dialog {
		@include media-breakpoint-down(xs) {
			margin:unset;
		}
		@media (min-width: 768px) and (max-width: 1199.98px) {
			max-width: 650px;
		}
		
	}
	.wpcf7 {
		.wpcf7-form {
			text-align: left;
			display: flex;
			flex-flow: column;
			label {
				margin-bottom: 20px;
			}
			span {

				input,
				textarea {
					width: 100%;
					border: 3px solid theme-color('accent-color');
					border-radius: 35px;
					font-size: $font-size-400;
					padding: 10px;
					padding-left: 20px;

					&:focus {
						outline: unset;
					}
				}

				textarea {
					height: 240px !important;
					@include media-breakpoint-down(xs) {
						height: 120px !important;
					}
				}
			}

			.wpcf7-form-control {
				width: 100%;
				height: 50px;
				border-radius: 28px;
				display: flex;
				justify-content: center;
				letter-spacing: 1px;

				@include media-breakpoint-down(xs) {
					height: 40px;
				}

				&.wpcf7-submit {
					height: 50px;
					@include media-breakpoint-down(sm) {
						margin-top: 0px;
						margin-bottom: 0px;
					}
				}

			}
			.wpcf7-response-output {
				margin: unset;
			}
			.wpcf7-not-valid-tip {
				padding-left: 10px;
			}
		}
	}
}

.modal.is-visible {
	visibility: visible;
	display: initial;
}

.modal-overlay {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: hsla(0, 0%, 0%, 0.5);
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
	opacity: 1;
	visibility: visible;
	transition-delay: 0s;
}

.modal-wrapper {
	position: absolute;
	overflow-y:auto; 
	z-index: 9999;
	top: 1em;
	background-color: #fff;
	box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
	border-radius: 50px;
	@include media-breakpoint-down(xs) {
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		min-height: 100vh;
		border-radius: 0;
	}
	@media (max-width: 350px) {
		border-radius: 0px;
	}
	.modal-body {
		padding: 52px;
		@include media-breakpoint-down(xs) {
			padding: 20px;
		}
		.modal-textbox {
			display: flex;
			flex-flow: column;
			text-align: left;
			padding-bottom: 20px;
			a {
				padding-bottom: 10px;
			}
			@include media-breakpoint-down(xs) {
				h3 {
					padding: 15px;
					width: 90%;
				}
			}
			.heading_button {
				display: flex;
				justify-content: left;
				@include media-breakpoint-down(xs) {
					width: 90%;
				}
				h2 {
					margin-bottom: 20px;
				}
			}
			.modal-toggle {
				position:absolute;
				display: inline-block;
				top: 20px;
				right: 20px;
				cursor: pointer;
				@include media-breakpoint-down(xs) {
					top: 0px;
					right: 20px;
					text-align: right;
				}
				img {
					width: 50%;
				}
			}
		}
	}
}

.modal-transition {
	transition: all 0.3s 0.12s;
	transform: translateY(-10%);
	opacity: 0;
}

.modal.is-visible .modal-transition {
	transform: translateY(0);
	opacity: 1;
}

.modal-header,
.modal-content {
	padding: 1em;
}

.modal-header {
	position: relative;
	background-color: #fff;
	box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
	border-bottom: 1px solid #e8e8e8;
}

.modal-close {
	position: absolute;
	top: 0;
	right: 0;
	padding: 1em;
	color: #aaa;
	background: none;
	border: 0;
}

.modal-close:hover {
	color: #777;
}

.modal-heading {
	font-size: 1.125em;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.modal-content>*:first-child {
	margin-top: 0;
}

.modal-content>*:last-child {
	margin-bottom: 0;
}
.wpcf7-response-output {
	margin-top: unset !important;
	border: 2px solid theme-color('primary') !important;
	font-family: $font-family-gilroy-regular;
}
.wpcf7-not-valid-tip {
	font-family: $font-family-gilroy-regular !important;
	margin-bottom: -20px;
}