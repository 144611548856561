.repeater_team-container {
	.repeater_content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;


		.repeater_heading {
			text-align: center;
			margin: auto;
			margin-bottom: 50px;
		}

		.teambox {
			padding-top: 100px;
			padding-bottom: 50px;
			&:nth-last-child(-n+2){
				padding-bottom: 0;
			}
			@include media-breakpoint-down(sm) {
				margin: auto;
				padding-top: 50px;
				padding-bottom: 50px;
			}

			.image_teambox {
				display: inline-block;
				padding-bottom: 50px;


				img {
					width: 50%;
				}

				@include media-breakpoint-down(sm) {
					padding-bottom: 25px;
				}
			}

			.text_teambox {
				h4 {
					padding-bottom: 25px;
					margin-bottom: unset;
				}
				p {
					font-size: $font-size-400;
					line-height: $spacing-250;
				}
			}
		}
	}
}