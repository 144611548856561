.reference {
	margin-bottom: 150px;
	position: relative;
	overflow: hidden;

	.repeater_heading {
		margin: auto;
		text-align: center;
	}


	.repeater_content {
		margin-top: 80px;
		max-height: 500px;
		transition: max-height 2s ease-in;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		&.show-all {
			max-height: 9999px;
			transition: max-height 2s ease-in;
		}

		.logo {
			border-bottom: 2px solid theme-color('section-grey');

			&:nth-last-child(1) {
				border-bottom: unset;
			}

			@include media-breakpoint-up(md) {
				border-right: 2px solid theme-color('section-grey');
				border-bottom: 2px solid theme-color('section-grey');

				&:nth-child(3n+0) {
					border-right: unset;
				}

				&:nth-last-child(1),
				&:nth-last-child(2),
				&:nth-last-child(3) {
					border-bottom: unset;
				}
			}

			img {
				-webkit-filter: grayscale(100%);
				/* Safari 6.0 - 9.0 */
				filter: grayscale(100%);

				&:hover,
				&:active,
				&:focus {
					filter: unset;
					transition: filter 0.25s ease-out;
					-webkit-transition: filter 0.25s ease-out;
					-moz-transition: filter 0.25s ease-out;
					-o-transition: filter 0.25s ease-out;
				}
			}
		}
	}

	.read_more {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		text-align: center;
		margin: 0;
		padding-top: 150px;

		/* "transparent" only works here because == rgba(0,0,0,0) */
		background-image: linear-gradient(to bottom, rgba(white, 0), white);
		background-image: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0), white);
		background-image: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0), white);
		background-image: -ms-linear-gradient(to bottom, rgba(0, 0, 0, 0), white);
		background-image: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0), white);

		a {
			display: flex;
			flex-flow: row;
			justify-content: center;

			.see_more_text {
				display: flex;
				flex-flow: column;
				justify-content: center;
				color: theme-color('primary');
				margin-bottom: unset;
				font-weight: $font-weight-bold;

				&:hover {
					text-decoration: none;
				}
			}

			.arrow_down {
				display: inline-block;

				img {
					text-align: center;
					width: 50%;
				}
			}

			&:hover {
				text-decoration: none;
			}
		}
	}
}