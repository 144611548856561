.foot {
	.image {
		position: relative;
		display: flex;

		img {
			position: absolute;
			bottom: 0;
		}
	}
}

footer {
	height: 100px;
	background-color: theme-color("accent-color");
	display: flex;
	justify-content: center;
	flex-flow: column;

	.footer-menu {
		display: flex;
		flex-flow: row;
		justify-content: space-between;

		@include media-breakpoint-down(sm) {
			flex-flow: column;
		}
		.menu-footer-links-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
		}

		.footer-message {
			color: $white;
			@include media-breakpoint-down(sm) {
				text-align: center;
				align-self: center;
				padding: 10px;
				padding-top:0px;
			}
		}

		.footer-submenu {
			@include media-breakpoint-down(sm) {
				margin: auto;
				padding: 10px;
				padding-top: 0px;
			}
			#footer_links {
				display: flex;
				flex-flow: row;
				flex-wrap: wrap;
				justify-content: flex-end;
				@include media-breakpoint-down(sm) {
					justify-content: center;
				}

				a {
					color: $white;

					&:hover {
						text-decoration: none;
					}
				}

				li {
					text-align: center;
					padding-left: 10px;
					padding-right: 10px;
					border-right: 1px solid white;

					&:nth-last-child(1) {
						border-right: unset;
					}
				}
			}
		}
	}
}