#cookiebar {
    position: fixed;
    display: flex;
    bottom: 0;
    width: 100%;
    min-height: 140px;
    z-index: 100000;
    text-align: center;
    background-color: $white !important;
    color: theme-color('primary') !important;
    border-top:2px solid theme-color('secondary');
    @media(min-width: map-get($grid-breakpoints, md)){
        text-align: left;
    }

    p {
		margin-bottom: 24px;
		color: theme-color('accent-color') !important;
        a { 
            color: theme-color('secondary') !important;
        }
        @media(min-width: map-get($grid-breakpoints, md)){
            margin: inherit;
        }
    }
    
    a {
        &.btn-tertiary {
            font-family:$font-family-gilroy-semiBold;
            background-color: $white;
			color: theme-color('primary') !important;
			width: 200px;
            margin: 0 auto;
            border:2px solid theme-color('primary') !important;
            &:hover {
                background-color: theme-color('primary') !important;
                color: $white !important;
            }
        }
        &:not(.btn-tertiary) {
            color: theme-color('primary');
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .container {
        padding-top: 24px;
        padding-bottom: 24px;
        .row {
            align-items: center;
        }
    }
}
