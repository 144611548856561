html,
body {
	height: 100%;
}

body {
	display: flex;
	flex-direction: column;
}

.content {
	flex: 1 0 auto;
}


img {
	max-width: 100%;
	height: auto;
}

button:focus {
	outline: none;
}

p {
	font-family: $font-family-gilroy-regular;
	font-weight: $font-weight-normal;
	font-size: $font-size-500;
	color: theme-color('accent-color');
	letter-spacing: 0.6px;
	line-height: $spacing-regular;

	@include media-breakpoint-down(xxs) {
		font-size: $font-size-300;
		line-height: $spacing-200;
	}
}

li {}

h1 {
	font-family: $font-family-gilroy-semiBold;
	font-weight: $font-weight-bold;
	font-size: $h1-font-size-mobile;
	color: theme-color('accent-color');
	letter-spacing: 0.3px;
	text-align: center;
	line-height: $spacing-h1-mobile;

	@include media-breakpoint-up(md) {
		font-size: $h1-font-size;
		line-height: $spacing-h1;
	}
}

h2 {
	font-family: $font-family-gilroy-bold;
	font-size: $h2-font-size-mobile;
	color: theme-color('accent-color');
	letter-spacing: 0.3px;
	line-height: $spacing-h2-mobile;

	@include media-breakpoint-up(md) {
		font-size: $h2-font-size;
		line-height: $spacing-h2;
	}
}

h3 {
	font-family: $font-family-gilroy-semiBold;
	font-weight: $font-weight-medium;
	font-size: $h3-font-size;
	color: theme-color('accent-color');
	letter-spacing: 0.3px;
	line-height: $spacing-h3;
}

h4 {
	font-family: $font-family-gilroy-semiBold;
	font-weight: $font-weight-bold;
	font-size: $h4-font-size;
	color: theme-color('accent-color');
	letter-spacing: 0.3px;
	line-height: $spacing-h4;
}

h5 {
	font-family: $font-family-roboto;
	font-weight: $font-weight-bold;
	font-size: $h5-font-size;
	color: theme-color('paragraph-color');
	letter-spacing: 0;
	line-height: $spacing-h5;
}

@media (min-width: 1470px) {
	.container {
		max-width: 1170px !important;
	}
}

.hide {
	display: none;
}

.showw {
	display: block !important;
}

.subheading {
	font-family: $font-family-roboto;
	font-weight: $font-weight-medium;
	font-size: $font-size-400;
	color: theme-color('paragraph-color');
	letter-spacing: 0;
	text-align: center;
	line-height: $spacing-regular;
}

.margin-25 {
	height: 25px;

	@include media-breakpoint-down(sm) {
		height: 12.5px;
	}
}

.grey-background {
	background-color: theme-color('section-grey');
}
.accent-background {
	background-color: theme-color('secondary');
}
.padding-120 {
	padding-top: 120px;
	padding-bottom: 120px;

	@include media-breakpoint-down(sm) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}
.padding-bottom-120 {
	padding-bottom: 120px;
	@include media-breakpoint-down(sm) {
		padding-bottom: 60px;
	}
}
.only-mobile {

	@include media-breakpoint-up(xs) {

		display: none;
	}
}

.margin-50 {
	height: 50px;

	@include media-breakpoint-down(sm) {
		height: 25px;
	}
}

.margin-75 {
	height: 75px;

	@include media-breakpoint-down(sm) {
		height: 37.5px;
	}
}

.margin-100 {
	height: 100px;

	@include media-breakpoint-down(sm) {
		height: 50px;
	}
}

.margin-125 {
	height: 125px;

	@include media-breakpoint-down(sm) {
		height: 125px;
	}
}

.margin-150 {
	height: 150px;

	@include media-breakpoint-down(sm) {
		height: 75px;
	}
}

.margin-175 {
	height: 175px;

	@include media-breakpoint-down(sm) {
		height: 87.5px;
	}
}

.margin-200 {
	height: 200px;

	@include media-breakpoint-down(sm) {
		height: 100px;
	}
}

.margin-240 {
	height: 240px;

	@include media-breakpoint-down(sm) {
		height: 120px;
	}
}

.margin-300 {
	height: 300px;

	@include media-breakpoint-down(sm) {
		height: 150px;
	}
}

.margin-320 {
	height: 320px;

	@include media-breakpoint-down(sm) {
		height: 160px;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}