.bewerbung {
	.bewerbungs_content {
		display: flex;
		justify-content: space-between;

		.left-content {
			margin-left: auto;
			margin-right: auto;

			.repeater_heading {
				margin-bottom: 50px;

				@include media-breakpoint-down(xs) {
					margin-bottom: 25px;
				}

				h1 {
					font-family: $font-family-freightText;
					letter-spacing: 1.5px;
					text-align: left;

					@include media-breakpoint-down(xs) {
						font-size: $font-size-800;
						line-height: $spacing-600;
						letter-spacing: 0;
					}
				}
			}

			.repeater_subtitle {
				p {
					line-height: $spacing-h3;
					@include media-breakpoint-down(xs) {
						line-height: $spacing-h4;
					}
				}
			}
			.repeater_content {
				.job_box {
					h4 {
						font-family: $font-family-gilroy-semiBold;
					}
					padding-top: 30px;
					padding-bottom: 30px;
					border-top: 2px solid black;
					&:nth-last-child(1) {
						border-bottom: 2px solid black;
					}
					.publication_link {
						a {
							font-size: $font-size-500;
							font-family: $font-family-gilroy-semiBold;
							letter-spacing: 0.5px;
							&:hover {
								text-decoration: none;
							}

							.arrow_icon {
								display: inline-block;
								padding-left: 50px;
								img {
									width: 50%;
								}
								@include media-breakpoint-down(xs) {
									display: none;
								}
							}
						}
					}
				}
			}
		}

		.right-content {
			margin-left: auto;
			margin-right: auto;

		}

		.upload-image {
			z-index: -2;
			position: absolute;
			display: inline-block;
			text-align: center;
			top: 25px;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			width: 320px;

			img {
				width: 50%;
			}

			@media (max-width: 400px) {
				width: auto;
			}
		}

		.wpcf7 {

			.wpcf7-form {
				text-align: center;
				display: flex;
				flex-flow: column;

				label {
					width: 100%;
					margin-bottom: 30px;
					@include media-breakpoint-down(xs) {
						margin-bottom: 15px;
					}

					span {

						input,
						textarea {
							width: 100%;
							border: 3px solid theme-color('accent-color');
							border-radius: 35px;
							font-size: $font-size-400;
							padding: 10px;
							padding-left: 20px;

							&:focus {
								outline: unset;
							}
						}

						textarea {
							height: 240px !important;
						}
					}
				}

				.codedropz-upload-handler {
					margin-top: 25px;
					border: 4px dashed theme-color('accent-color');
					border-radius: 36px;
					height: 310px;
				}

				.dnd-upload-status {
					.dnd-upload-details {
						.name {
							font-size: $font-size-400;
							text-align: left;
							font-family: $font-family-gilroy-regular;
							color: theme-color('accent-color');
							letter-spacing: 0.2px;
							line-height: 26px;

							em {
								font-style: normal;
								font-family: $font-family-gilroy-semiBold;
								font-weight: $font-weight-normal;
							}
						}
					}

					.dnd-progress-bar {
						.complete {
							background: theme-color('accent-color');
						}
					}
				}

				.codedropz-upload-wrapper {
					text-align: center;
					text-align: -webkit-center;
				}

				.codedropz-upload-container {
					padding-top: 160px;

					@media (max-width: 400px) {
						padding-top: 120px;
					}

					.codedropz-upload-inner {
						white-space: unset;

						h3 {
							font-size: $font-size-500;
							margin: unset;
						}

						span {
							font-size: $font-size-400;
						}

						@include media-breakpoint-down(md) {}
					}
				}

				.wpcf7-form-control {
					width: 100%;
					height: 62px;
					border-radius: 40px;
					display: flex;
					justify-content: center;
					letter-spacing: 1px;

					&.wpcf7-submit {
						margin-top: 40px;
						margin-bottom: 40px;

						@include media-breakpoint-down(sm) {
							margin-top: 20px;
							margin-bottom: 20px;
						}
					}
				}
			}
		}

		.only-desktop {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		.only-mobile {
			@include media-breakpoint-down(md) {
				display: block !important;
			}
		}

		.hide {
			display: none;
		}

		.publication_box {
			margin: auto;
			padding-top: 50px;
			padding-bottom: 50px;
			border-top: 2px solid theme-color('accent-color');

			@include media-breakpoint-down(sm) {
				padding-top: 25px;
				padding-bottom: 25px;
			}

			.publication_textbox {
				h4 {
					margin-bottom: 25px;

					@include media-breakpoint-down(sm) {
						margin-bottom: 12.5px;
					}
				}

				p {
					font-size: $font-size-400;
					line-height: $spacing-250;
					letter-spacing: 0.2px;
				}
			}

			.publication_link {

				.arrow_icon {
					padding-left: 30px;
					display: inline-block;

					img {
						width: 50%;
						vertical-align: middle;
					}

					@media (max-width:450px) {
						display: none;
					}
				}

				a {
					font-size: $font-size-500;
					transition: all ease-in-out 0.1s;

					&:hover {
						text-decoration: none;
						transition: all ease-in-out 0.1s;
					}
				}
			}

			&:nth-last-child(1) {
				border-bottom: 2px solid theme-color('accent-color');
			}
		}
	}
}
.bewerbung .bewerbungs_content .wpcf7 .wpcf7-form label span input, .bewerbung .bewerbungs_content .wpcf7 .wpcf7-form label span textarea {
	padding: 15px;
}