.publication {
	.repeater_content {
		.publication_box {
			margin: auto;
			padding-top: 50px;
			padding-bottom: 50px;
			border-top: 4px solid theme-color('section-grey');
			@include media-breakpoint-down(sm) {
				padding-top: 25px;
				padding-bottom: 25px;
			}
			.publication_textbox {
				h3 {
					margin-bottom: 25px;

					@include media-breakpoint-down(sm) {
						margin-bottom: 12.5px;
					}
				}
			}
			.publication_link {
				
				.arrow_icon {
					padding-left: 30px;	
					display: inline-block;
					vertical-align: text-bottom;
					
					img {
						width: 50%;
						vertical-align: middle;
					}
				}
				a {
					transition: all ease-in-out 0.1s;
					&:hover {
						text-decoration: none;
						transition: all ease-in-out 0.1s;
					}
				}
			}
			&:nth-last-child(1) {
				border-bottom: 4px solid theme-color('section-grey');
			}
		}
	}
}