.textbox-container {
	background-color: theme-color('secondary');
	padding-top: 250px;
	padding-bottom: 250px;
	@include media-breakpoint-down(sm) {
		padding-top: 125px;
		padding-bottom: 125px;
	}
	.textbox-heading {
		z-index: 10;
		margin: auto;
		h2,
		h4,
		h3,
		h6 {
			text-align: center;
			color: $white;
		}
		@include media-breakpoint-down(sm) {
			h2 {
				font-size: $font-size-680;
			}
		}
		h3 {
			padding-bottom: 20px;
		}

		h6 {
			font-family: $font-family-gilroy-regular;
			font-size: $font-size-400;
			color: $white;
			letter-spacing: 0.2px;
			text-align: center;
			line-height: $spacing-250;
			margin-top: 20px;

			@include media-breakpoint-down(xs) {
				font-size: $font-size-200;
				letter-spacing: 0.15px;
			}
		}
	}

	.background_image {
		display: inline-block;
		position: absolute;
		margin: auto;
		margin-top: 60px;
		left: 100px;
		right: 100px;
		text-align: center;
		opacity: 0.1;
		max-width: 500px;

		img {
			width: 50%;
		}

		@include media-breakpoint-down(xs) {
			left: 0;
			right: 0;
			margin-top: 240px;
		}
	}
}