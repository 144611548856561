.dataprivacy-impressum {
	.image_title {
		justify-content: center;
		padding-bottom: 50px;
		@include media-breakpoint-down(xs) {
			h1 {
				font-size: $font-size-700;
			}
			
		}
	}
	.maincontent {
		justify-content: center;
		.maincontent {
			display: flex;
			justify-content: center;
		}
		@include media-breakpoint-down(xs) {
			h2 {
				font-size: $font-size-600;
				line-height: $font-size-700;
			}
		}
	}
	.background-image {
		img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: -1;
		}
	}
}